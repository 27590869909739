@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.App {
  padding-top: 1%;
}

.body{
  text-align: center;
}

.shortCard {
  height: 50%;
}

.ourHeader {
  font-size: 275%;
  font-weight: bold;
  
  padding-top: .5%;
  text-align: center;
}

.gradient-text {
  background-image: linear-gradient(60deg, #3a3939, #858585);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.ourSubHeader {
  font-size: 150%;
  font-weight: bold;
  background-color: #fdfdfd;
  color: #353535;
}

.container-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.question {
  font-size: 135%;
  font-weight: bold;
  padding-bottom: .5%;
  margin-bottom: 1.5%;
  padding-top: 2%;
}

.h3 {
  color: red;
  font-weight: bold;
}

.card {
  padding-top: 1%;
  justify-content: center;
  color: #181818;
  border: 0;
}

.hCenter {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center content */
  align-items: center; /* Horizontally center content */
  height: 70vh; /* Set the container height to 100% of the viewport height */
  animation: fadeIn 3s;
}

.thankYou {
  font-size: 500%;
  font-weight: bold;
  color: #353535;
}

.btn {
  margin: 2%
}

.btn-custom-start {
  padding: 16px 38px;
  /* Adjust the padding to make the button larger */
  font-size: 24px;
  /* Adjust the font size to make the text larger */
  /* Add any other custom styles you want */
  /* border-radius: 2rem; */
  font-weight: bold;
  /* background-image: linear-gradient(60deg, #4e3636, #858585); */
  background-color: #4a4a4a;
  color: #eee
}

.btn-dark {
  background-color: #359c4f !important;
  border-width: 0rem !important;
  font-weight: bold !important;
  padding: .5rem 1rem !important;

}

.btn-dark:hover {
  background-color: #246935 !important;
}

.btn-outline-success {
  font-weight: bold !important;
  color: #fdfdfd !important;
  background-color: #359c4f !important;
  border-width: 0rem !important;
}

.btn-outline-success:hover {
  background-color: #246935 !important;

}

.questionBox {
  margin-top: 1%;
  width: 100%;
  height: 50%;
  overflow: visible;
  border-radius: 2rem;
  /* Adjust the value to control the degree of rounding */
  display: flex;
  /* Use Flexbox */
  justify-content: end;
  /* Horizontally center */
  align-items: end;
  text-align: left;
  /* Vertically center */
}

.col-1 {
  padding-left: 2%;
  padding-right: 0%
}

.Recruitify{
  border-radius: 1rem;
  font-size: 135%;
  font-weight: bold;
  height: 100%;
  text-align: left;
}

.cardOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: white;
}

.cardOverlayUser {
  position: absolute;
  color:white
}

.transparent-box {
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value (0.5) for the desired transparency */
  padding-top: 5px;

  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px; /* Optional: Add border-radius for rounded corners */
  text-align: center; /* Optional: Center the text horizontally */
  color: white; /* Optional: Set text color to white */
}

.timer {
  font-size: 95%;
  font-weight: bold;
  text-align: center;
  align-items: center;
  color: #484848;
  display: flex;
  justify-content: center; /* Centers child elements (like Timer) horizontally */
}

.timer > * {
  flex: 1; /* Makes the Timer component take the full width */
}

.processing {
  font-size: 75%;
  text-align: center;
  font-weight: lighter;
  color: #484848
}

.subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
}

.interviewName {
  text-align: left;
}
.r-al {
  display: flex;
  align-items: right;
  justify-content: right;
}

.bumper {
  margin: 1rem
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1.5s infinite;
   -moz-animation: flickerAnimation 1.5s infinite;
   -o-animation: flickerAnimation 1.5s infinite;
    animation: flickerAnimation 1.5s infinite;
}

.center-items {
  /* display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  min-height: 65vh; */
  margin-top: 4rem;
}

@media (max-width: 768px) {
  .row {
    align-items: left;
  }
  .vert {
    flex-direction: column; /* Stack columns on smaller screens */
  }

  .col-6 {
    width: 100%; /* Make both columns take up the full width */
    margin-bottom: 10px; /* Add some spacing between the stacked columns */
    margin-left: 0;
    padding-left: 0 !important;
  }

  /* Add any other necessary styles for mobile layout */
}