.col {
  text-align: center;
}
@media (max-width: 768px) {
  .player-wrapper {
    border-radius: 1rem;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.react-player {
  border-radius: 1rem;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}


.speaking {
  top:0;
  position: absolute;
}

.asdfasdf {
  position: relative;
}